<template>
  <el-row :gutter="20">
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>待发货订单</span>
          <el-tag effect="dark" type="danger" size="mini">急</el-tag>
        </div>
        <div class="desc-num">{{ desc.wait_send }}</div>
        <div class="y-desc">待发货</div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>订单</span>
          <el-tag effect="dark" size="mini">待</el-tag>
        </div>
        <div class="desc-num">{{ desc.wait_service }}</div>
        <div class="y-desc">退换货</div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>商品</span>
          <el-tag effect="dark" type="danger" size="mini">急</el-tag>
        </div>
        <div class="desc-num">{{ desc.goods_stock_warning }}</div>
        <div class="y-desc">库存预警</div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>待提现</span>
          <el-tag effect="dark" type="danger" size="mini">待</el-tag>
        </div>
        <div class="desc-num">{{ desc.wait_cash }}</div>
        <div class="y-desc">待提现</div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>订单</span>
          <el-tag effect="dark" size="mini">昨</el-tag>
        </div>
        <div class="desc-num">{{ desc.yesterday_paid_order }}</div>
        <div class="flex-def flex-zBetween flex-cCenter y-desc">
          <div>昨日支付订单数</div>
          <div :style="elColor(desc.yesterday_paid_order_statistics)">
            {{desc.yesterday_paid_order_statistics}}%
            <i v-if="desc.yesterday_paid_order_statistics > 0" class="el-icon-top"></i>
            <i v-else-if="desc.yesterday_paid_order_statistics < 0" class="el-icon-bottom"></i>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>交易</span>
          <el-tag effect="dark" size="mini">昨</el-tag>
        </div>
        <div class="desc-num">{{ desc.yesterday_money }}</div>
        <div class="flex-def flex-zBetween flex-cCenter y-desc">
          <div>昨日交易额</div>
          <div :style="elColor(desc.yesterday_money_statistics)">
            {{desc.yesterday_money_statistics}}%
            <i v-if="desc.yesterday_money_statistics > 0" class="el-icon-top"></i>
            <i v-else-if="desc.yesterday_money_statistics < 0" class="el-icon-bottom"></i>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>用户</span>
          <el-tag effect="dark" size="mini">今</el-tag>
        </div>
        <div class="desc-num">{{ desc.today_added_member }}</div>
        <div class="flex-def flex-zBetween flex-cCenter y-desc">
          <div>今日新增用户</div>
          <div :style="elColor(desc.today_added_member_statistics)">
            {{desc.today_added_member_statistics}}%
            <i v-if="desc.today_added_member_statistics > 0" class="el-icon-top"></i>
            <i v-else-if="desc.today_added_member_statistics < 0" class="el-icon-bottom"></i>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="desc-item y-pointer">
        <div class="flex-def flex-zBetween flex-cCenter" slot="header">
          <span>用户</span>
          <el-tag effect="dark" size="mini">月</el-tag>
        </div>
        <div class="desc-num">{{ desc.this_month_added_member }}</div>
        <div class="flex-def flex-zBetween flex-cCenter y-desc">
          <div>本月新增用户</div>
          <div :style="elColor(desc.this_month_added_member_statistics)">
            {{desc.this_month_added_member_statistics}}%
            <i v-if="desc.this_month_added_member_statistics > 0" class="el-icon-top"></i>
            <i v-else-if="desc.this_month_added_member_statistics < 0" class="el-icon-bottom"></i>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "desc_section",
  props:{
    desc:{
      type:Object,
      default(){
        return{}
      }
    }
  },
  methods:{
    elColor(num){
      return num < 0 ? 'color:red;' : 'color:blue;';
    }
  }
}
</script>

<style scoped>
.desc-item {
  margin-bottom: 1rem;
}

.desc-num {
  font-size: 1.6rem;
}
</style>